import { Clipboard } from '@angular/cdk/clipboard';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
	providedIn: 'root',
})
export class ShareService {
	constructor(
		private snackBar: MatSnackBar,
		private clipboard: Clipboard,
	) {}

	public shareUrl(title: string, url: string) {
		const shareData: ShareData = {
			title: title,
			text: title,
			url: url,
		};

		if (navigator.canShare && navigator.canShare(shareData)) {
			navigator.share(shareData);
		} else {
			this.clipboard.copy(url);
			this.snackBar.open('Link copied to clipboard', 'OK', {
				duration: 3000,
			});
		}
	}

	public copyLinkToClipboard(url: string) {
		this.clipboard.copy(url);
		this.snackBar.open('Link copied to clipboard', 'OK', {
			duration: 3000,
		});
	}
}
