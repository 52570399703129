import { Roles } from '../shared/shared.types';

export class UserClaimModel {
	constructor(claimId: number, claimTask: number, claimName: string) {
		this.claimId = claimId;
		this.claimTask = claimTask;
		this.claimName = claimName;
	}

	private claimId: number;
	private claimTask: number;
	private claimName: string;

	getClaimId(): number {
		return this.claimId;
	}

	getClaimTask(): number {
		return this.claimTask;
	}

	getClaimName(): string {
		return this.claimName;
	}
}

export class UserRoleModel {
	constructor(roleId: number, roleName: string) {
		this.roleId = roleId;
		this.roleName = roleName;
	}

	private roleId: number;
	private roleName: string;

	getRoleId(): number {
		return this.roleId;
	}

	getRoleName(): string {
		return this.roleName;
	}
}

export class SubscriptionModel {
	constructor(
		subscriptionId: string,
		userId: string,
		subscriptionStatus: string,
		hasActiveSubscription: boolean,
		hasCancelledSubscription: boolean,
		hasActiveTrialSubscription: boolean,
		nextBillingCycleDateUtc: string | null,
		billingPeriodUnit: string,
		trialEndDateUtc: string | null,
		cancelledDateUtc: string | null,
	) {
		this.subscriptionId = subscriptionId;
		this.userId = userId;
		this.subscriptionStatus = subscriptionStatus;
		this.hasActiveSubscription = hasActiveSubscription;
		this.hasCancelledSubscription = hasCancelledSubscription;
		this.hasActiveTrialSubscription = hasActiveTrialSubscription;
		this.nextBillingCycleDateUtc = nextBillingCycleDateUtc;
		this.billingPeriodUnit = billingPeriodUnit;
		this.trialEndDateUtc = trialEndDateUtc;
		this.cancelledDateUtc = cancelledDateUtc;
	}

	private subscriptionId: string;
	private userId: string;
	private subscriptionStatus: string;
	private hasActiveSubscription: boolean;
	private hasCancelledSubscription: boolean;
	private hasActiveTrialSubscription: boolean;
	private nextBillingCycleDateUtc: string | null;
	private billingPeriodUnit: string;
	private trialEndDateUtc: string | null;
	private cancelledDateUtc: string | null;

	getSubscriptionId(): string {
		return this.subscriptionId;
	}

	getUserId(): string {
		return this.userId;
	}

	getSubscriptionStatus(): string {
		return this.subscriptionStatus;
	}

	getHasActiveSubscription(): boolean {
		return this.hasActiveSubscription;
	}

	getHasCancelledSubscription(): boolean {
		return this.hasCancelledSubscription;
	}

	getHasActiveTrialSubscription(): boolean {
		return this.hasActiveTrialSubscription;
	}

	getNextBillingCycleDateUtc(): string | null {
		return this.nextBillingCycleDateUtc;
	}

	getBillingPeriodUnit(): string {
		return this.billingPeriodUnit;
	}

	getTrialEndDateUtc(): string | null {
		return this.trialEndDateUtc;
	}

	getCancelledDateUtc(): string | null {
		return this.cancelledDateUtc;
	}
}

export class InstructorProgramModel {
	constructor(instructorId: string, programId: string, programName: string, startingReleaseNumber: number | undefined) {
		this.instructorId = instructorId;
		this.programId = programId;
		this.programName = programName;
		this.startingReleaseNumber = startingReleaseNumber;
	}

	private instructorId: string;
	private programId: string;
	private programName: string;
	private startingReleaseNumber: number | undefined;

	getInstructorId(): string {
		return this.instructorId;
	}

	getProgramId(): string {
		return this.programId;
	}

	getProgramName(): string {
		return this.programName;
	}

	getStartingReleaseNumber(): number | undefined {
		return this.startingReleaseNumber;
	}
}

export class UserModel {
	constructor(
		userId: string,
		firstName: string | undefined,
		lastName: string | undefined,
		email: string | undefined,
		instructorId: string | null,
		profileBlurb: string | null,
		userProfileIsPublic: boolean,
		instructorProfilePictureUrl: string | null,
		instructorProfileThumbnailUrl: string,
		canChangeName: boolean | undefined,
		roles: UserRoleModel[],
		selectedPrograms: InstructorProgramModel[],
		showOnlySelectedProgramsInFeed: boolean,
		hasDefaultProfilePicture: boolean,
		hideProfileCompletionPrompt: boolean,
		subscriptionReadModel: SubscriptionModel | null,
	) {
		this.userId = userId;
		this.firstName = firstName;
		this.lastName = lastName;
		this.email = email;
		this.instructorId = instructorId;
		this.profileBlurb = profileBlurb;
		this.userProfileIsPublic = userProfileIsPublic;
		this.instructorProfilePictureUrl = instructorProfilePictureUrl;
		this.instructorProfileThumbnailUrl = instructorProfileThumbnailUrl;
		this.canChangeName = canChangeName;
		this.roles = roles;
		this.selectedPrograms = selectedPrograms;
		this.showOnlySelectedProgramsInFeed = showOnlySelectedProgramsInFeed;
		this.hasDefaultProfilePicture = hasDefaultProfilePicture;
		this.hideProfileCompletionPrompt = hideProfileCompletionPrompt;
		this.subscriptionReadModel = subscriptionReadModel;
	}

	private userId: string;
	private firstName: string | undefined;
	private lastName: string | undefined;
	private email: string | undefined;
	private instructorId: string | null;
	private profileBlurb: string | null;
	private userProfileIsPublic: boolean;
	private instructorProfilePictureUrl: string | null;
	private instructorProfileThumbnailUrl: string;
	private canChangeName: boolean | undefined;
	private roles: UserRoleModel[];
	private selectedPrograms: InstructorProgramModel[];
	private showOnlySelectedProgramsInFeed: boolean;
	private hasDefaultProfilePicture: boolean;
	private hideProfileCompletionPrompt: boolean;
	private subscriptionReadModel: SubscriptionModel | null;

	getUserId(): string {
		return this.userId;
	}

	getFirstName(): string | undefined {
		return this.firstName;
	}

	getLastName(): string | undefined {
		return this.lastName;
	}

	getEmail(): string | undefined {
		return this.email;
	}

	getInstructorId(): string | null {
		return this.instructorId;
	}

	getProfileBlurb(): string | null {
		return this.profileBlurb;
	}

	getUserProfileIsPublic(): boolean {
		return this.userProfileIsPublic;
	}

	getInstructorProfilePictureUrl(): string | null {
		return this.instructorProfilePictureUrl;
	}

	getInstructorProfileThumnbnailUrl(): string {
		return this.instructorProfileThumbnailUrl;
	}

	getCanChangeName(): boolean | undefined {
		return this.canChangeName;
	}

	getRoles(): UserRoleModel[] {
		return this.roles;
	}

	getSelectedPrograms(): InstructorProgramModel[] {
		return this.selectedPrograms;
	}

	getShowOnlySelectedProgramsInFeed(): boolean {
		return this.showOnlySelectedProgramsInFeed;
	}

	getHasDefaultProfilePicture(): boolean {
		return this.hasDefaultProfilePicture;
	}

	getHideProfileCompletionPrompt(): boolean {
		return this.hideProfileCompletionPrompt;
	}

	getSubscriptionModel(): SubscriptionModel | null {
		return this.subscriptionReadModel;
	}
}

export class UserDataModel {
	constructor(isAuthenticated: boolean, userData: UserModel | undefined) {
		this._isAuthenticated = isAuthenticated;
		this.userData = userData;
	}

	private _isAuthenticated: boolean;
	private userData: UserModel | undefined;

	isAuthenticated(): boolean {
		return this._isAuthenticated;
	}

	getUserData(): UserModel | undefined {
		return this.userData;
	}

	hasProgramAdminRole(): boolean {
		return this.userData?.getRoles().some((role) => role.getRoleId() === Roles.ProgramAdmin) ?? false;
	}

	hasProgramManagerRole(): boolean {
		return this.userData?.getRoles().some((role) => role.getRoleId() === Roles.ProgramManager) ?? false;
	}

	hasSystemAdminRole(): boolean {
		return this.userData?.getRoles().some((role) => role.getRoleId() === Roles.SystemAdmin) ?? false;
	}

	hasInstructorRole(): boolean {
		return this.userData?.getRoles().some((role) => role.getRoleId() === Roles.Instructor) ?? false;
	}

	hasActiveSubscription(): boolean {
		return this.userData?.getSubscriptionModel()?.getHasActiveSubscription() ?? false;
	}

	hasActiveTrialSubscription(): boolean {
		return this.userData?.getSubscriptionModel()?.getHasActiveTrialSubscription() ?? false;
	}

	hasActiveSubscriptionOrTrial(): boolean {
		return this.hasActiveTrialSubscription() || this.hasActiveSubscription();
	}
}
