import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class MyPlaylistService {
	private myPlaylistChangedSource = new BehaviorSubject<string>('');
	currentMessage = this.myPlaylistChangedSource.asObservable();

	signalMyPlaylistChanged(changeEvent: string) {
		this.myPlaylistChangedSource.next(changeEvent);
	}
}
