import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { IUserResponse } from './models/auth.types';
import {
	UserDataModel,
	SubscriptionModel,
	UserModel,
	UserRoleModel,
	InstructorProgramModel,
} from '../../models/user.model';
import { environment } from '../../../environments/environment';

export interface IAuthService {
	login(returnUrl?: string): void;
	createNewAccount(returnUrl?: string): void;
	logout(): void;
	isAuthenticated(): Promise<boolean>;
	getUserProfile(): Promise<string>;
}

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private userInfoSubject = new BehaviorSubject<IUserResponse | null>(null);
	public userInfo$ = this.userInfoSubject.asObservable();

	constructor(private http: HttpClient) {}

	get(): IUserResponse | null {
		return this.userInfoSubject.getValue();
	}

	async getUserInfo(): Promise<UserDataModel> {
		const response = await lastValueFrom(this.http.get<IUserResponse>(`/api/account/user`));
		let subscription: SubscriptionModel | null = null;
		if (response?.userData?.subscriptionReadModel) {
			subscription = new SubscriptionModel(
				response.userData.subscriptionReadModel.subscriptionId,
				response.userData.subscriptionReadModel.userId,
				response.userData.subscriptionReadModel.subscriptionStatus,
				response.userData.subscriptionReadModel.hasActiveSubscription,
				response.userData.subscriptionReadModel.hasCancelledSubscription,
				response.userData.subscriptionReadModel.hasActiveTrialSubscription,
				response.userData.subscriptionReadModel.nextBillingCycleDateUtc,
				response.userData.subscriptionReadModel.billingPeriodUnit,
				response.userData.subscriptionReadModel.trialEndDateUtc,
				response.userData.subscriptionReadModel.cancellationDateUtc,
			);
		}

		let userData: UserModel | undefined = undefined;
		if (response?.userData) {
			const roles = response?.userData?.roles.map((role) => {
				return new UserRoleModel(role.roleId, role.roleName);
			});

			let selectedPrograms: InstructorProgramModel[] = [];
			if (response?.userData?.selectedPrograms) {
				selectedPrograms = response?.userData?.selectedPrograms.map((program) => {
					return new InstructorProgramModel(
						program.instructorId,
						program.programId,
						program.programName,
						program.startingReleaseNumber,
					);
				});
			}

			userData = new UserModel(
				response?.userData?.userId,
				response?.userData?.firstName,
				response?.userData?.lastName,
				response?.userData?.email,
				response?.userData?.instructorId,
				response?.userData?.profileBlurb,
				response?.userData?.userProfileIsPublic,
				response?.userData?.instructorProfilePictureUrl,
				response?.userData?.instructorProfileThumbnailUrl,
				response?.userData?.canChangeName,
				roles,
				selectedPrograms,
				response?.userData?.showOnlySelectedProgramsInFeed,
				response?.userData?.hasDefaultProfilePicture,
				response?.userData?.hideProfileCompletionPrompt,
				subscription,
			);
		}

		return new UserDataModel(response.isAuthenticated, userData);
	}

	login(returnUrl?: string) {
		const encodedReturnUrl = encodeURIComponent(returnUrl || window.location.href);

		window.location.href = `${environment.API_URL}/api/account/login?returnUrl=${encodedReturnUrl}`;
	}

	createNewAccount(returnUrl?: string) {
		const encodedReturnUrl = encodeURIComponent(returnUrl || window.location.href);
		window.location.href = `${environment.API_URL}/api/account/create-account?returnUrl=${encodedReturnUrl}`;
	}

	logout() {
		window.location.href = `${environment.API_URL}/api/account/logout`;
	}

	async createAccount(): Promise<IUserResponse> {
		return await lastValueFrom(this.http.post<IUserResponse>(`/api/account/instructor`, {}));
	}
}
