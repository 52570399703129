import { Injectable, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { UserDataModel } from '../../models/user.model';

@Injectable({
	providedIn: 'root',
})
export class UserStoreService {
	public readonly userData$ = signal<UserDataModel>(new UserDataModel(false, undefined));
	private readonly userDataInternal = new BehaviorSubject<UserDataModel>(new UserDataModel(false, undefined));
	public readonly userDataStateChanged$ = this.userDataInternal.asObservable();
	private isInitialized: boolean = false;
	public readonly isInitializing$ = signal(false);

	constructor(private authService: AuthService) {}

	get user() {
		return this.userData$();
	}

	set user(val: UserDataModel) {
		this.userData$.set(val);
	}

	public async initialize(): Promise<void> {
		if (this.isInitialized) {
			return;
		}

		this.isInitializing$.set(true);

		this.user = await this.authService.getUserInfo();
		this.userDataInternal.next(this.user);
		this.isInitializing$.set(false);
		this.isInitialized = true;
	}

	public async reinitialize(): Promise<void> {
		this.isInitialized = false;
		await this.initialize();
	}
}
